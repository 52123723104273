import React, { useState, useEffect, useRef } from "react";
import { IoMdSend } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { ChatState, initialState, updateChatState } from "./chatState";
import { paths } from "./paths";
import TypingIndicator from "./TypingIndicator";
import { getCurrentUser, syncUserWithServer } from "src/features/profile/state/profileSlice";
import { useAppDispatch } from "src/common/state/hooks";
import {
  resetActiveGptConversation,
  createChat,
  updateChat,
  askWixarpedia,
  setActiveGptConversation,
  fetchGptConversations,
} from "../state/chatbotSlice";
import ChatBubble from "./ChatBubble";
import GraphBubble from "./GraphBubble";
import { IGptConversation } from "src/model/model";
import "./Chatbot.scss";
import { useSelector } from "react-redux";
import { GPT_CONVERSATION_LOADER_COMPONENT_ID } from "./GptConversationLoader";

interface Message {
  text: string;
  sender: "bot" | "user";
}

interface Option {
  value: string;
  label: string;
  next?: string;
}

interface ChatbotProps {
  mode: "scenarioHelper" | "wixarpedia";
  activeConversation?: IGptConversation | null;
}

const Chatbot: React.FC<ChatbotProps> = ({ mode, activeConversation }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  // State to manage when the user has interacted with the chatbot via free text
  const [hasInteracted, setHasInteracted] = useState(false);

  const loadInitialMessages = () => {
    if (mode === "wixarpedia") {
      const savedMessages = localStorage.getItem("wixarpediaChatMessages");
      return savedMessages
        ? JSON.parse(savedMessages)
        : [{ text: "Bonjour ! Comment puis-je vous aider ?", sender: "bot" }];
    }
    return [];
  };

  const [chatState, setChatState] = useState<ChatState>(initialState);
  const [currentPath, setCurrentPath] = useState("metier");
  const [isBotTyping, setIsBotTyping] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [messages, setMessages] = useState<Message[]>(loadInitialMessages());
  const [options, setOptions] = useState<Option[]>([]);
  const [userInput, setUserInput] = useState("");
  const [inputMode, setInputMode] = useState(mode !== "scenarioHelper");

  const bottomRef = useRef<HTMLDivElement>(null);

  const user = useSelector(getCurrentUser);

  useEffect(() => {
    if (mode === "wixarpedia") {
      localStorage.setItem("wixarpediaChatMessages", JSON.stringify(messages));
    }
  }, [messages, mode]);

  useEffect(() => {
    if (mode === "scenarioHelper") {
      if (activeConversation) {
        loadPreviousConversation();
      } else {
        resetChatLocally();
      }
    } else if (mode === "wixarpedia") {
      resetChatLocally();
    }
  }, [mode, activeConversation]);

  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, isBotTyping]);

  useEffect(() => {
    if (user?.active_gpt_conversation && user?.active_gpt_conversation?.id) {
      dispatch(setActiveGptConversation(user?.active_gpt_conversation));
    }
  }, [user?.active_gpt_conversation?.id]);

  const loadPreviousConversation = () => {
    if (activeConversation && mode === "scenarioHelper") {
      setHasInteracted(true); // Mark as interacted since this is an existing conversation

      const { conversation_metadata, conversation_data } = activeConversation;

      setChatState((prevState) => ({
        ...prevState,
        userFormResults: conversation_metadata.userFormResults,
      }));

      const savedMessages: Message[] = [];
      const { userPrompts } = conversation_metadata;
      const { output } = conversation_data;

      const totalMessages = Math.max(userPrompts.length, output.length);

      for (let i = 0; i < totalMessages; i++) {
        if (i < userPrompts.length) {
          savedMessages.push({ text: userPrompts[i], sender: "user" });
        }
        if (i < output.length) {
          savedMessages.push({ text: output[i], sender: "bot" });
        }
      }

      setMessages(savedMessages);
      setInputMode(true);
    }
  };

  const handleOptionSelect = (selectedOption: Option) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: selectedOption.label, sender: "user" },
    ]);
    setChatState((prevState) =>
      updateChatState(
        prevState,
        currentPath as keyof ChatState["userFormResults"],
        selectedOption.value,
      ),
    );
    setOptions([]);
    setIsBotTyping(true);

    setTimeout(() => {
      setIsBotTyping(false);

      if (selectedOption.next) {
        const nextPath = selectedOption.next;
        setCurrentPath(nextPath);
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: paths[nextPath].question, sender: "bot" },
        ]);
        setOptions(paths[nextPath]?.options || []);
      } else {
        setInputMode(true);
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: "Merci. Veuillez décrire votre problématique.", sender: "bot" },
        ]);
      }
    }, 300);
  };

  const handleUserPromptSubmit = async () => {
    if (!userInput.trim()) return;

    const userMessage = userInput;
    setUserInput("");
    setIsSubmitting(true);

    setMessages((prevMessages) => [
      ...prevMessages,
      { text: userMessage, sender: "user" },
      { text: "", sender: "bot" },
    ]);

    setIsBotTyping(true);
    setHasInteracted(true); // User has now interacted, no more form chat

    if (mode === "scenarioHelper") {
      if (activeConversation) {
        await dispatch(
          updateChat({
            userPrompt: userMessage,
            conversationId: activeConversation.id,
            cb: (chunk) => updateBotMessage(chunk),
          }),
        );
        dispatch(syncUserWithServer({}));
      } else {
        // Otherwise, create a new conversation
        await dispatch(
          createChat({
            userPrompt: userMessage,
            userFormResults: chatState.userFormResults,
            cb: (chunk) => updateBotMessage(chunk),
            cbOnRequestEnd: () => {
              dispatch(
                fetchGptConversations({ componentId: GPT_CONVERSATION_LOADER_COMPONENT_ID }),
              );
              dispatch(syncUserWithServer({}));
            },
          }),
        );
      }
    } else if (mode === "wixarpedia") {
      await dispatch(
        askWixarpedia({
          userPrompt: userMessage,
          cb: (chunk) => updateBotMessage(chunk),
        }),
      );
    }

    setIsSubmitting(false);
    setIsBotTyping(false);
  };

  const updateBotMessage = (chunk: string) => {
    setMessages((prevMessages) => {
      const updatedMessages = [...prevMessages];
      const lastMessageIndex = updatedMessages.length - 1;
      updatedMessages[lastMessageIndex] = {
        ...updatedMessages[lastMessageIndex],
        text: updatedMessages[lastMessageIndex].text + chunk,
      };
      return updatedMessages;
    });
  };

  const resetChatLocally = () => {
    setChatState(initialState);
    setCurrentPath("metier");
    setHasInteracted(false); // Reset interaction state for new chat

    const initialMessage =
      mode === "scenarioHelper"
        ? paths["metier"].question
        : "Bonjour ! Comment puis-je vous aider aujourd'hui ?";

    setMessages([
      {
        text: initialMessage,
        sender: "bot",
      },
    ]);

    setInputMode(mode !== "scenarioHelper");
    setIsBotTyping(false);
    setUserInput("");

    if (mode === "wixarpedia") {
      localStorage.removeItem("wixarpediaChatMessages");
    }

    if (mode === "scenarioHelper") {
      setOptions(paths["metier"].options);
      dispatch(resetActiveGptConversation());
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && userInput.trim() && !isSubmitting) {
      handleUserPromptSubmit();
    }
  };

  return (
    <div className="flex">
      <div className="flex m-2 w-full flex-col items-center">
        <div className="w-5/6 max-w-[80vw] h-[80vh] mx-auto p-4 bg-white shadow-lg rounded-lg flex flex-col">
          <div className="chat-window bg-gray-100 p-4 flex-grow overflow-auto rounded-md flex flex-col space-y-4">
            {messages.map((message, index) => (
              <div key={index + 2 * index}>
                <ChatBubble customKey={index} sender={message.sender} text={message.text} />
                {message.text.includes("Voici un exemple de scénario pour votre cas d'usage") && (
                  <GraphBubble markdown={message.text} />
                )}
              </div>
            ))}
            {isBotTyping && (
              <div className="flex justify-start">
                <div className="px-4 py-2 rounded-3xl bg-gray-300 text-black">
                  <TypingIndicator />
                </div>
              </div>
            )}
            {!isBotTyping && options.length > 0 && !hasInteracted && mode === "scenarioHelper" && (
              <div className="flex flex-col space-y-2">
                {options.map((option) => (
                  <button
                    key={option.value}
                    className="px-4 py-2 rounded-3xl bg-blue-500 text-white self-end"
                    onClick={() => handleOptionSelect(option)}
                  >
                    {option.label}
                  </button>
                ))}
              </div>
            )}
            <div ref={bottomRef} />
          </div>
          {inputMode && (
            <div className="flex justify-between items-center p-2 bg-white border-t">
              <div className="flex-grow px-4 py-2 rounded-3 bg-blue-100 text-black">
                <input
                  type="text"
                  value={userInput}
                  onChange={(e) => setUserInput(e.target.value)}
                  onKeyDown={handleKeyDown}
                  className="w-full bg-transparent outline-none"
                  placeholder={
                    mode === "scenarioHelper"
                      ? t("pages.chat.scenarioHelperInputPlaceholder")
                      : t("pages.chat.wixarpediaInputPlaceholder")
                  }
                  disabled={isSubmitting}
                />
              </div>
              <button
                className="bg-blue-500 text-white w-12 h-12 rounded-md ml-2 flex items-center justify-center"
                onClick={handleUserPromptSubmit}
                disabled={!userInput || isSubmitting}
                style={{ cursor: userInput.length && !isSubmitting ? "default" : "not-allowed" }}
              >
                <IoMdSend size={24} />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Chatbot;
