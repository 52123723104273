import { useDispatch, useSelector } from "react-redux";
import ModalStandard from "../../../common/components/Modal/ModalStandard";
import { closeStandardModal } from "../../../common/state/slice/modal/modalSlice";
import { AppDispatch } from "../../../common/state/store";
import { IMedia } from "../../../model/unityObject";
import { Gallery } from "../../gallery/Gallery";
import Alert from "../../../common/components/Alert/Alert";
import { getCurrentProject } from "src/features/projects/state/projectsSlice";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { currentFilterSelector } from "src/features/gallery/state/gallerySlice";
import { isStringNullOrEmpty } from "src/common/util/isStringNullOrEmpty";

// Todo: have 'nodeId' for: ["profile", "organization", "background_audio"]

const ModalGallery = ({
  onMediaChange,
  onCancel,
  nodeId,
  contentType,
}: {
  nodeId: string;
  onMediaChange: (id: IMedia) => void;
  onCancel?: () => void;
  contentType?: string;
}) => {
  const dispatch: AppDispatch = useDispatch();

  const onCancelDefault = () => {
    dispatch(closeStandardModal("modal-gallery"));
  };

  const { pathname } = useLocation();
  const currentProject = useSelector(getCurrentProject);
  const currentMediaFilter = useSelector(currentFilterSelector);
  const [navTitle, setNavTitle] = useState("");

  // WIXAR-3811 | Incomplete path displayed in ModalGallery when editing a project
  useEffect(() => {
    if (
      /^\/project-groups\/\d{1,256}\/list\/\d{1,256}\/graph\/node\/[^/]+\/\d{1,256}$/.test(pathname)
    ) {
      setNavTitle(currentProject?.name);
    }
  }, [pathname, currentProject]);

  return (
    <ModalStandard
      id="modal-gallery"
      fullScreen={true}
      isStatic
      modalType="standard"
      clickOnBackgroundClose={false}
    >
      <div className="flex w-full">
        <div className="text-center ">
          <Alert />
        </div>
        <div className="grow overflow-y-visible ">
          <Gallery
            onConfirmSelectMedia={(media: IMedia) => {
              if (
                (isStringNullOrEmpty(currentMediaFilter?.mediaType) ||
                  currentMediaFilter?.mediaType === "all" ||
                  currentMediaFilter?.mediaType === media.type) &&
                (isStringNullOrEmpty(currentMediaFilter?.media360tag) ||
                  currentMediaFilter?.media360tag === "all" ||
                  currentMediaFilter?.media360tag === media.media_360_tag)
              ) {
                onMediaChange(media);
              }
            }}
            onCancel={() => (onCancel ? onCancel() : onCancelDefault())}
            nodeId={nodeId}
            contentType={contentType}
            isModalEdit={false}
            isMediaPickerMode={true}
            title={navTitle}
          />
        </div>
      </div>
    </ModalStandard>
  );
};

export default ModalGallery;
