import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Input from "../../common/components/Input/Input";
import { useForm } from "react-hook-form";
import {
  getNewProjectSelector,
  INewProjectFormValues,
  setNewProject,
  getEditedProject,
  setEditedName,
  getCurrentProject,
} from "../../features/projects/state/projectsSlice";
import { getSelectedProjectGroup } from "../../features/projectGroups/state/projectGroupsSlice";
import { useAppDispatch } from "../../common/state/hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../common/state/store";
import { useEffect, useState } from "react";
import { FooterButtons } from "../../common/components/FooterButtons";
import isStringLegalProjectName from "../../common/util/isStringLegalProjectName";
import { getCurrentUser } from "src/features/profile/state/profileSlice";

interface IProjectNewName {
  incrementStep(): void;
  decrementStep(): void;
  mode?: string;
}

const ProjectNewName = ({ incrementStep, mode = "" }: IProjectNewName) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const path = location.pathname.split("/");

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<INewProjectFormValues>();

  const { newProject, selectedProjectGroup, editedProject, currentProject, currentUser } =
    useSelector((state: RootState) => {
      return {
        newProject: getNewProjectSelector(state),
        selectedProjectGroup: getSelectedProjectGroup(state),
        editedProject: getEditedProject(state),
        currentProject: getCurrentProject(state),
        currentUser: getCurrentUser(state),
      };
    });

  const [inputValueNewProject, setinputValueNewProject] = useState<string>(currentProject.name);
  const userPreferredLanguage = currentUser.preferred_language?.id;

  const onSubmit = (data: INewProjectFormValues) => {
    if (isStringLegalProjectName(inputValueNewProject)) {
      if (mode === "edit") {
        dispatch(setEditedName(data.newName));
      } else if (mode === "faitTemplate") {
        dispatch(
          setNewProject({
            newName: data.newName,
            projectId: Number(currentProject.id),
            thumbnailId: 0,
            projectGroupId: 0,
            languageId: userPreferredLanguage ?? 1,
            enabledLanguagesIds: userPreferredLanguage ? [String(userPreferredLanguage)] : [],
          }),
        );
      } else {
        dispatch(
          setNewProject({
            ...newProject,
            newName: data.newName,
            projectGroupId: selectedProjectGroup.id,
          }),
        );
      }
      incrementStep();
    }
  };

  useEffect(() => {
    if (mode === "edit") {
      setValue("newName", editedProject.name);
    }
    if (mode !== "edit" && mode !== "faitTemplate") {
      setValue("newName", newProject.newName);
    }
  }, []);

  const title = () => {
    switch (mode) {
      case "edit":
        return t("pages.editProjectName.pleaseRename");
      case "faitTemplate":
        return t("pages.editProjectName.editTemplateName");
      default:
        return t("chooseTemplateType.projectName");
    }
  };

  const projectNamesInsideThisProjectGroup = selectedProjectGroup.project_group_elements
    ? [...selectedProjectGroup.project_group_elements]
        .sort((a, b) => a.order - b.order)
        .map((pge) => pge.project.name)
    : [];

  return (
    <>
      <h1 className="text-2xl my-5 mx-6 font-medium">{title()}</h1>
      <form className="flex flex-col grow w-full justify-between" onSubmit={handleSubmit(onSubmit)}>
        <div className="mx-6 max-w-md">
          <Input
            autoFocus
            type="text"
            placeholder={t("chooseTemplateType.placeholder")}
            registerFct={() =>
              register("newName", {
                required: true,
                maxLength: 24,
                value: currentProject.name,
                onChange(event) {
                  setinputValueNewProject(event.target.value);
                },
                validate: {
                  projectWithThisNameAlreadyExists: (v) =>
                    !projectNamesInsideThisProjectGroup.includes(v) ||
                    (v === currentProject?.name && mode === "edit"),
                },
              })
            }
          />
          {errors.newName?.type === "maxLength" && (
            <span className="text-red-600 text-sm mt-2">{t("alerts.maxCharacters")}</span>
          )}
          {!isStringLegalProjectName(inputValueNewProject) && (
            <span className="text-red-600 text-sm mt-2">{t("alerts.wrongProjectNameFormat")}</span>
          )}
          {errors.newName?.type === "required" && (
            <span className="text-red-600 text-sm mt-2">{t("stepper.nameProjectValidation")}</span>
          )}
          {errors.newName?.type === "projectWithThisNameAlreadyExists" && (
            <span className="text-red-600 text-sm mt-2">
              {t("alerts.aProjectWithThisNameAlreadyExistsInTheProjectGroup")}
            </span>
          )}
        </div>

        <FooterButtons
          cancelText={t("general.cancel")}
          cancelCb={() => {
            if (path[1] === "templates") {
              navigate("/templates");
            } else {
              navigate(
                `/project-groups/${selectedProjectGroup.id}/list/${currentProject.id}/graph`,
              );
            }
          }}
          backText={t("general.back")}
          backDisabled={mode === "edit" || mode === "faitTemplate"}
          backCb={() => navigate(-1)}
          nextText={t("general.next")}
        />
      </form>
    </>
  );
};

export default ProjectNewName;
